import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StatusNavBar from '../components/StatusNavBar';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: 120,
    margin: 'auto',
    textAlign: 'center'
  },
}));

export default function NotFound() {
const classes = useStyles();

  return (
    <div className={classes.root}>
      <StatusNavBar></StatusNavBar>
      <Container className={classes.container} >
      <img
            alt="Under development"
            src="../images/404.png"
            style={{
              marginTop: 50,
              display: 'inline-block',
              maxWidth: '90%',
              width: 560
            }}
          />
      </Container>
  </div>
  );
}
