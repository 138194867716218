import React, { useState , useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Loading from '../components/Loading';
import WeatherDetails from '../components/WeatherDetails';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: '#EFC'
  },
  griditem: {
      padding: 0
  }
}));


export default function WeatherCardDetail(props) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [ isLoading, setLoading] = useState(true);


  useEffect(() => {
    //console.log('i ran')
    const fetchData = async () => {
     const result = await axios(
       "/api/Weather",
     );
     setData(result.data);
     setLoading(false)
   };
    fetchData();
  }, []);

  //console.log(data)

  return (
    <div className={classes.root}>
      <div>{isLoading ? <Loading></Loading> :
        <div>
            <WeatherDetails 
                city={data.name}
                main={data.weather[0].main}
                temp={data.main.temp}
                windspeed={data.wind.speed}
                winddirection={data.wind.deg}
                humidity={data.main.humidity}
                pressure={data.main.pressure}
                visibility={data.visibility}                
            >
            </WeatherDetails>
            
        </div>} 
    </div>
  </div>
  );
}
