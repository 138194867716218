import React, { useState , useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import  Container  from '@material-ui/core/Container';
import axios from 'axios';
import { Redirect } from "react-router";
import Loading from '../components/Loading'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 100,
        paddingLeft: 5,
        paddingRight: 5,
    },
    stringify: {
      width: 40,
    }
}));


function checkPermission( userData ) {
  //console.log(userData.clientPrincipal.userRoles)
  var requiredRole = false


  try {
    var userRolesArray = userData.clientPrincipal.userRoles
  }
  catch(err) {
    return ( 
      <div>
        <Typography variant="h3" textAlign="left">
                  Not Authorized
          </Typography>
          <Redirect push to="/logout" />
      </div>
    );
  }
  //console.log( ' this is the user data check permission ',userData)
  //console.log( ' this is the user data check permission ',userRolesArray)

  userRolesArray.forEach(function (item) {
   // console.log(item);
    if (item === "appuser"){
        requiredRole = true
    };
  });

  // set to true for testing
  //console.log(requiredRole)

  if ( requiredRole === true) {
    return (
    <div>
          <Typography variant="h3" textAlign="left">
                  Authorized
          </Typography>
          <Redirect push to="/app/dashboard" />
      </div>
    );
  } else {
    return(
      <div>
          <Typography variant="h3" textAlign="left">
                  Not Authorized
          </Typography>
          <Redirect push to="/logout" />
      </div>)
  }
}

export default function LoginSuccess() {
  const classes = useStyles();
  const [data, setData] = useState();
  const [ isLoading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
     const result = await axios(
       "/.auth/me",
     );
     setData(result.data);
     setLoading(false)
   };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
        <Container>
            <div>{isLoading ? <Loading></Loading> : checkPermission(data)} </div>
            <div className={classes.stringify}>
            <Typography variant="caption">FromAPICall: {JSON.stringify(data)}</Typography>
            </div>
      </Container>
    </div>
      );
    }