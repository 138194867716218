import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography  from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: '#EFC'
  },
  griditem: {
      padding: 0
  }
}));

export default function WeatherCard(props) {
  const classes = useStyles();

  return (
    <Card
    {...props} elevation={0} className={classes.root}>
    <CardContent>
      <Grid
        container
        spacing={0}
      >
        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography
            color="textPrimary"
            variant="h6"
            align='Center'
          >
            {props.city}
            </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
            align='center'
          >
            {props.main}
          </Typography>
          <Typography
            color="textPrimary"
            variant="h1"
            align='center'
          >
            {props.temp}<span>&#8457;</span>
          </Typography>
          <br></br>
          <Divider />
          <br></br>
        </Grid>
        
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.griditem}>
        <Typography color="textPrimary"
            variant="caption" display="block"
            align='center'>
                Wind: {props.windspeed}
        </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.griditem}>
        <Typography color="textPrimary"
            variant="caption" display="block"
            align='center'>
                Wind Direction: {props.winddirection}
        </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.griditem}>
        <Typography color="textPrimary"
            variant="caption" display="block"
            align='center'>
                Humidity: {props.humidity}%
        </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.griditem}>
        <Typography color="textPrimary"
            variant="caption" display="block"
            align='center'>
                Pressure: {props.pressure}InHg
        </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.griditem}>
        <Typography color="textPrimary"
            variant="caption" display="block"
            align='center'> 
                Visibility: {props.visibility} Feet
        </Typography>
        
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  );
}
