import { createMuiTheme, colors } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#14A774'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  typography: {
    fontFamily: "Open Sans, Helvetica",
    h1: {
      '@media (min-width:0px)': {
        fontSize: '3.5rem',
      },
      '@media (min-width:600px)': {
        fontSize: '4.7rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '5.3rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '5.9rem',
      },
    },
    h2: {
    '@media (min-width:0px)': {
      fontSize: '2.3rem',
    },
    '@media (min-width:600px)': {
      fontSize: '2.5rem',
    },
    '@media (min-width:1280px)': {
      fontSize: '2.9rem',
    },
    '@media (min-width:1920px)': {
      fontSize: '3.3rem',
    },
    },
    h3: {
      '@media (min-width:0px)': {
        fontSize: '1.8rem',
      },
      '@media (min-width:600px)': {
        fontSize: '2.3rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '2.7rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '2.9rem',
      },
      },
    button: {
      textTransform: "none"
    }
  }
});

export default theme
