import { ThemeProvider } from '@material-ui/core/styles';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import Login from './pages/Login'
import LoginSuccess from './pages/loginSuccess'
import NotFound from './pages/Notfound'
import Dashboard from './pages/Dashboard'
import Profile from './pages/Profile'
import Devices from './pages/Devices'
import Zones from './pages/Zones'
import theme from './theme'
import './App.css';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
      <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/loginsuccess' exact component={LoginSuccess} />
        <Route path='/app/Dashboard' exact component={Dashboard} />
        <Route path='/app/profile' exact component={Profile} />
        <Route path='/app/devices' exact component={Devices} />
        <Route path='/app/zones' exact component={Zones} />
        <Route path='/login' exact component={Login} />
        <Route path='/404' component={NotFound} />
      </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
