import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600
  },
  large: {
    width: theme.spacing(11),
    height: theme.spacing(11),
    margin: 'auto'
  },
  paper: {
    borderRadius: 10,
    padding: theme.spacing(2),
  },
  paperuserdetail: {
    overflow: 'auto',
    backgroundColor: '#282C34',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    color: '#fff',
    borderRadius: 0
  }
}));

export default function ProfileUserPaper(props) {
  const classes = useStyles();
  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid item xs={12}>
            <Avatar className={classes.large}>H</Avatar>
        </Grid>
        <Grid item xs={12}>
            <Typography align='center' variant='h4'> First Name </Typography>
            <Typography align='center' variant='h5'> Last Name</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align='center' variant='body1'> Provider </Typography>
            <Typography variant='body2' align='center' >{props.userinfo.clientPrincipal.identityProvider} </Typography>
            <br></br>
            <Typography align='center' variant='body1'> Email </Typography>
            <Typography align='center' ariant='body2'>{props.userinfo.clientPrincipal.userDetails} </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paperuserdetail} elevation={0}>
              {JSON.stringify(props.userinfo)}
          </Paper>
        </Grid>
        </Paper>
    </div>
  );
}
