import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ResponsiveDrawer from '../components/ResponsiveDrawer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        marginTop: 60,
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.primary
    },
    gridcontainer: {
        marginTop: 70,
    },
    button: {
        marginTop: 20,
        height: 50,
    },
    logo: {
        maxWidth: 40,
        marginRight: '10px'
      }
}));

export default function Home() {
const classes = useStyles();

  return (
    <div className={classes.root}>
        <ResponsiveDrawer></ResponsiveDrawer>
          <Typography variant='h1'>
            Devices
          </Typography>
    </div>
  );
}
