import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ResponsiveDrawer from '../components/ResponsiveDrawer';
import WeatherCardDetail from '../components/WeatherCardDetail';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        marginTop: 60,
        padding: theme.spacing(2)
    },
    box: {
      //backgroundColor: '#fff',
      width: '100%'
  },
}));

export default function Dashboard() {
const classes = useStyles();

  return (
    <div className={classes.root}>
        <ResponsiveDrawer></ResponsiveDrawer>
        <Box className={classes.box}>
    <Container maxWidth={false}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={4}
          sm={12}
          md={6}
          xl={2}
          xs={12}
        >
            <WeatherCardDetail ></WeatherCardDetail>
        </Grid>
        <Grid
          item
          lg={4}
          sm={12}
          md={6}
          xl={2}
          xs={6}
        >
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
  
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
    
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
     
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
 
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
 
        </Grid>
      </Grid>
    </Container>
  </Box>
    </div>
  );
}
