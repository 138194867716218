import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Button from "@material-ui/core/Button";
import {NavLink as RouterLink,} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
  },
  routerlink: {
    width: '90%',
    margin: 'auto',
    textDecoration: 'none',
  },
  routerlinkactive: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  linkButton: {
    color: theme.palette.text.secondary,
  },
}));

export default function DrawerItem(props) {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <ListItem
        disableGutters
        className={classes.listitem}
      >
        <RouterLink to={props.href} className={classes.routerlink} activeClassName={classes.routerlinkactive} >
        <Button
        fullWidth={true}
        style={{justifyContent: "flex-start"}}
        className={classes.linkButton}
        >
          {props.icon}
          {props.title}
        </Button>
        </RouterLink>
      </ListItem>
    </div>
  );
}
