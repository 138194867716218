import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../images/logo.png";
import Google from "../images/google.png";
import Microsoft from "../images/microsoft.png";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import StatusNavBar from '../components/StatusNavBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.primary,
        height: '400px'
    },
    container: {
        marginTop: 90,
    },
    button: {
        marginTop: 20,
        height: 50,
    },
    logo: {
        maxWidth: 40,
        marginRight: '10px'
    },
    logosignin: {
      maxWidth: 200,
      paddingBottom: 30,
  },
  logocompanies: {
    maxWidth: 30,
    marginRight: '10px'
},
}));

export default function Login() {
const classes = useStyles();

  return (
    <div className={classes.root}>
      <StatusNavBar></StatusNavBar>
    <Container maxWidth="sm" className={classes.container}>
    <Paper className={classes.paper} elevation={0}>
    <img src={logo} alt="IoT" className={classes.logosignin} />
              <Typography variant="h5" textAlign="left">
                  Sing In
              </Typography>

              <Button 
                fullWidth={true} 
                size="large" 
                color="primary"
                variant="contained"
                className={classes.button}
                href="/.auth/login/google?post_login_redirect_uri=https://www.vgg.mritconsulting.com/loginsuccess"
                >
                  <img src={Google} alt="IoT" className={classes.logocompanies} />
                  LOGIN WITH GOOGLE
              </Button>
     
              <Button 
                fullWidth={true} 
                size="large" 
                color="primary"
                variant="contained"
                className={classes.button}
                href="/.auth/login/aad?post_login_redirect_uri=https://www.vgg.mritconsulting.com/loginsuccess"
                >
                  <img src={Microsoft} alt="IoT" className={classes.logocompanies} />
                  LOGIN WITH MICROSOFT
              </Button>
          </Paper>
      </Container>
    </div>
  );
}
