import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../images/logo.png";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        maxWidth: 40,
        marginRight: '10px'
    },
}));

export default function StatusNavBar() {
const classes = useStyles();

  return (
    <div className={classes.root}>
    <AppBar position="static" elevation={0}>
        <Toolbar>
        <img src={logo} alt="IoT" className={classes.logo} />
        </Toolbar>
    </AppBar>
    </div>
  );
}
