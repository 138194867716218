import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from "../images/logo.png";
import DrawerItem from '../components/DrawerItem';

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar2: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  logo: {
    maxWidth: 40,
    marginLeft: 40
  },
  listitem: {
    borderRadius: 10,
    marginLeft: 13,
    width: '90%',
    color: theme.palette.text.secondary
  },
  icondiv: {
    marginRight: '-30px',
    paddingLeft: 7
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const itemsNav = [
    {
      href: '/app/dashboard',
      icon: <DashboardIcon />,
      title: 'Dashboard'
    },
    {
      href: '/app/devices',
      icon: <DeviceHubIcon />,
      title: 'Devices'
    },
    {
      href: '/app/zones',
      icon: <RoomIcon />,
      title: 'Zones'
    }
  ];

  const itemsUser = [
    {
      href: '/app/Profile',
      icon: <PersonIcon />,
      title: 'Profile'
    },
  ];



  const drawer = (
    <div >
      <div className={classes.toolbar} />
      <List>
      <ListItem  alignItems='flex' disableGutters>
        <img src={logo} alt="IoT" className={classes.logo} />
        </ListItem>
          {itemsNav.map((item) => (
            <DrawerItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
      </List>
      <Divider />
      <List>
          {itemsUser.map((item) => (
            <DrawerItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
      </List>
      <Divider />
      <List>
      <ListItem button  component="a" href="/logout" className={classes.listitem} disableGutters>
            < div className={classes.icondiv}>
             <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            </div>
             <ListItemText secondary='Logout'></ListItemText>
      </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Mountain Ridge IoT
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: false, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
