import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import ResponsiveDrawer from '../components/ResponsiveDrawer';
import axios from 'axios';
import ProfileUserPaper from '../components/ProfileUserPaper';

import Loading from '../components/Loading';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 60,
        padding: theme.spacing(2)
    },
}));

export default function Home() {
  const classes = useStyles();
  const [data, setData] = useState();
  //const [ isLoading, setLoading] = useState(true);
  const [ isLoading, setLoading] = useState(true);

   useEffect(() => {
     const fetchData = async () => {
      const result = await axios(
        "/.auth/me",
      );
      setData(result.data);
      setLoading(false)
    };
     fetchData();
   }, []);

   //console.log(data);

  //const datalocal = {"clientPrincipal":{"identityProvider":"localProvider","userId":"7653ff15a1554c1396a7328d976d259c","userDetails":"localuser@gmail.com","userRoles":["appuser","anonymous","authenticated"]}}

  return (
    <div className={classes.root}>
        <ResponsiveDrawer></ResponsiveDrawer>
        <Grid item spacing={4} alignItems='center' 
            lg={5}
            sm={12}
            md={6}
            xl={4}
            xs={12}>
          <div>{isLoading ? <Loading></Loading> : <ProfileUserPaper userinfo={data}></ProfileUserPaper>} </div>
        </Grid>
    </div>
      );
    }